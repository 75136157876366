import { render, staticRenderFns } from "./saleDiscountList.vue?vue&type=template&id=0e98b386&scoped=true&"
import script from "./saleDiscountList.vue?vue&type=script&lang=js&"
export * from "./saleDiscountList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e98b386",
  null
  
)

export default component.exports